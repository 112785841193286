import { provideIcons } from '@ng-icons/core';
import * as NgMatIcons from '@ng-icons/material-icons/baseline';
import * as NgSimpleIcons from '@ng-icons/simple-icons';

// TODO Storybook as documentation for these icons
export const NG_ICONS = {
  DRAFT: NgMatIcons.matInsertDriveFile,
  ARCHIVE: NgMatIcons.matArchive,
  OVERVIEW: NgMatIcons.matAnalytics,
  HOME: NgMatIcons.matHome,
  USER: NgMatIcons.matPerson2,
  LICENSE_TEMPLATES: NgMatIcons.matNoteAdd,
  CUSTOMERS: NgMatIcons.matPeople,
  ORGANIZATIONS: NgMatIcons.matApartment,
  LICENSES: NgMatIcons.matArticle,
  ACTIVATIONS: NgMatIcons.matDevicesOther,
  TRIALS: NgMatIcons.matTimer,
  TRIAL_POLICIES: NgMatIcons.matAvTimer,
  RELEASES: NgMatIcons.matCloudUpload,
  CHANNELS: NgMatIcons.matLayers,
  PLATFORMS: NgMatIcons.matCategory,
  FILES: NgMatIcons.matDriveFileMove,
  DEVELOPER: NgMatIcons.matCode,
  SETTINGS: NgMatIcons.matSettings,
  MAINTENANCE_POLICIES: NgMatIcons.matSystemUpdate,
  TAG: NgMatIcons.matLabel,
  PRODUCTS: NgMatIcons.matInventory2,
  WEBHOOKS: NgMatIcons.matWebhook,
  AUTOMATED_EMAILS: NgMatIcons.matMail,
  ACCESS_TOKENS: NgMatIcons.matToken,
  SDK_DOWNLOADS: NgMatIcons.matDownload,
  FEATURE_FLAGS: NgMatIcons.matFlag,
  PRODUCT_VERSIONS: NgMatIcons.matRule,
  PROFILE: NgMatIcons.matBadge,
  ACCOUNT: NgMatIcons.matAccountBox,
  BILLING_AND_PLANS: NgMatIcons.matPayments,
  ROLES: NgMatIcons.matSettingsInputComponent,
  TEAM: NgMatIcons.matGroups2,
  LOGS: NgMatIcons.matReceiptLong,
  RESELLERS: NgMatIcons.matPointOfSale,
  WHITELABELING: NgMatIcons.matPalette,
  DELETE: NgMatIcons.matDelete,
  SUCCESS: NgMatIcons.matCheckCircle,
  ERROR: NgMatIcons.matError,
  VIEW_IN_MODAL: NgMatIcons.matRemoveRedEye,
  WARNING: NgMatIcons.matWarning,
  FILTER: NgMatIcons.matFilterAlt,
  SEARCH: NgMatIcons.matSearch,
  MANAGE_SEARCH: NgMatIcons.matManageSearch,
  HELP: NgMatIcons.matHelpOutline,
  LIGHT_MODE: NgMatIcons.matLightMode,
  DARK_MODE: NgMatIcons.matDarkMode,
  UPLOAD_FILE: NgMatIcons.matUploadFile,
  UPLOADED_FILE: NgMatIcons.matTask,
  REPORTS: NgMatIcons.matAnalytics,
  COLUMNS: NgMatIcons.matViewColumn,
  ROWS: NgMatIcons.matTableRows,
  FIRST_PAGE: NgMatIcons.matFirstPage,
  PREVIOUS_PAGE: NgMatIcons.matNavigateBefore,
  NEXT_PAGE: NgMatIcons.matNavigateNext,
  LAST_PAGE: NgMatIcons.matLastPage,
  REFRESH: NgMatIcons.matRefresh,
  CREATE: NgMatIcons.matAdd,
  EDIT: NgMatIcons.matEdit,
  DOWNLOAD: NgMatIcons.matDownload,
  CLEAR: NgMatIcons.matClear,
  RESET_PASSWORD: NgMatIcons.matLockReset,
  SSO: NgMatIcons.matKey,
  DROPDOWN_EXPAND: NgMatIcons.matArrowDropDown,
  INFORMATION: NgMatIcons.matInfo,
  METADATA: NgMatIcons.matSchema,
  METER_ATTRIBUTES: NgMatIcons.matElectricMeter,
  ADVANCED_SETTINGS: NgMatIcons.matTune,
  NAVIGATE_TO: NgMatIcons.matStart,
  MENU_OPEN: NgMatIcons.matMenuOpen,
  MENU_CLOSED: NgMatIcons.matMenu,
  NODE_LOCKED: NgMatIcons.matLock,
  HOSTED_FLOATING: NgMatIcons.matCloud,
  ON_PREMISE_FLOATING: NgMatIcons.matLan,
  ACTION_MENU: NgMatIcons.matMoreVert,
  DETAILS: NgMatIcons.matInfo,
  OS_DEFAULT_THEME: NgMatIcons.matContrast,
  SAVE: NgMatIcons.matSave,
  COPY: NgMatIcons.matContentCopy,
  ACTIVATE: NgMatIcons.matToggleOn,
  DEACTIVATE: NgMatIcons.matToggleOff,
  CHANGE: NgMatIcons.matChangeCircle,
  PASSWORD_CHANGE: NgMatIcons.matPassword,
  SECURITY: NgMatIcons.matSecurity,
  PREVIEW: NgMatIcons.matPreview,
  PUBLISH: NgMatIcons.matRocketLaunch,
  RENEW: NgMatIcons.matAutorenew,
  EXTEND: NgMatIcons.matUpgrade,
  REVOKE_LICENSE: NgMatIcons.matCancel,
  APPROVE_LICENSE: NgMatIcons.matRecommend,
  SUSPEND_LICENSE: NgMatIcons.matFrontHand,
  ALLOW_LICENSE: NgMatIcons.matNextPlan,
  SHOW: NgMatIcons.matVisibility,
  HIDE: NgMatIcons.matVisibilityOff,
  RESET: NgMatIcons.matRestartAlt,
  SEND: NgMatIcons.matSend,
  PLAN_ALLOWED: NgMatIcons.matCheck,
  PLAN_NOT_ALLOWED: NgMatIcons.matClose,
  DATE: NgMatIcons.matEvent,
  UPLOAD: NgMatIcons.matUpload,
  SIGNUP: NgMatIcons.matPersonAdd,
  FEEDBACK: NgMatIcons.matFeedback,
  BUG_REPORT: NgMatIcons.matBugReport,
  GENERATE_PASSWORD: NgMatIcons.matSyncLock,
  WINDOWS: NgSimpleIcons.simpleWindows,
  LINUX: NgSimpleIcons.simpleLinux,
  IOS: NgSimpleIcons.simpleIos,
  MACOS: NgSimpleIcons.simpleMacos,
  ANDROID: NgSimpleIcons.simpleAndroid,
  AMEX: NgSimpleIcons.simpleAmericanexpress,
  DINERS_CLUB: NgSimpleIcons.simpleDinersclub,
  DISCOVER: NgSimpleIcons.simpleDiscover,
  JCB: NgSimpleIcons.simpleJcb,
  MASTERCARD: NgSimpleIcons.simpleMastercard,
  VISA: NgSimpleIcons.simpleVisa,
  FILTER_LIST: NgMatIcons.matFilterList,
  LOGIN: NgMatIcons.matLogIn,
  LOGOUT: NgMatIcons.matLogout,
  SERVERINFO: NgMatIcons.matApartment,
} as const;

export const CTX_NG_ICONS_PROVIDER = provideIcons(NG_ICONS);
export type CtxIcons = keyof typeof NG_ICONS;

/**
 *
 * @param isVisibile true if the field is visible
 * @returns appropriate icon string
 */
export function getVisibilityIcon(isVisibile: boolean): CtxIcons {
  return isVisibile ? 'HIDE' : 'SHOW';
}

type OSNames = 'windows' | 'linux' | 'macos' | 'android' | 'ios';
/**
 *
 * @param osName Operating System Name
 * @returns IconDefinition for the operating system name
 */
export function getIconForOs(osName: OSNames): CtxIcons {
  switch (osName) {
    case 'windows':
      return 'WINDOWS';
    case 'linux':
      return 'LINUX';
    case 'macos':
      return 'MACOS';
    case 'android':
      return 'ANDROID';
    case 'ios':
      return 'IOS';
  }
}
