<!-- Some material components have z-index set to 100 during runtime. The app-bar is central to the application layout. -->
<!-- This is why we set the z-index to 500 here. -->
<nav
  class="overflow-hidden h-14 sticky z-[500] top-0 mat-toolbar border-solid border-0 border-b bg-card"
>
  <span
    class="flex flex-row h-full items-center gap-4 box-border py-2 px-2"
    [ngClass]="{
      'justify-between': justifyBetween,
      'justify-end': justifyEnd
    }"
  >
    <span class="flex flex-row items-center">
      @if(showToggleButton){
      <button
        ctx-button--secondary
        aria-label="Toggle Side Navigation"
        color="accent"
        class="ml-2"
        [actionIcon]="sidenav.opened ? 'MENU_OPEN' : 'MENU_CLOSED'"
        (click)="sidenav.toggle()"
      ></button>
      }
      <ctx-company-logo class="pl-4"></ctx-company-logo>
    </span>

    @if (authn.trialExpirationDate && !authn.isTrialExpired) {
    <span class="text-sm opacity-80">
      Trial expires on:
      {{ authn.trialExpirationDate | date : 'mediumDate' }}
    </span>
    } @if ( subscriptionInfo && showPaymentPastDueMessage) {
    <span class="text-sm text-warning opacity-80">
      Payment failed. Update your
      <a [routerLink]="['/settings/billing-and-plans']" class="text-warning">
        payment method
      </a>
      to avoid service disruption.
    </span>

    }
    <ng-content></ng-content>
  </span>
</nav>
