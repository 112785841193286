import { CtxIcons } from './icons';

/**
 * @returns A formatted string for the number of bytes
 */
export function formatFilesize(bytes: number): string {
  if (bytes < 1024) {
    return `${bytes} B`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(1)} KB`;
  } else if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
}

/**
 * Returns the appropriate icon based on a release's lifecycle state
 * Draft -> Published -> Archived
 *
 * @param release
 * @returns CtxIcons
 */
export function getIconForReleaseStatus(release: any): CtxIcons {
  if (!release.published) {
    return 'DRAFT';
  }

  if (release.archived) {
    return 'ARCHIVE';
  }
  return 'PUBLISH';
}

/**
 *
 * @param release
 * @returns  Tooltip hint for release status icon
 */
export function getHintForReleaseStatus(release: any): string {
  if (!release.published) {
    return 'Draft';
  }

  if (release.archived) {
    return 'Archived';
  }
  return 'Published';
}
