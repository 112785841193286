export const API_BASE_URL_EU = 'https://api.eu.cryptlex.com';
export const API_BASE_URL_US = 'https://api.cryptlex.com';
export const API_BASE_URL_IN = 'https://api.dev.cryptlex.com';

export const RELEASE_BASE_URL_US = 'https://releases.cryptlex.com';
export const RELEASE_BASE_URL_IN = 'https://releases.dev.cryptlex.com';
export const RELEASE_BASE_URL_EU = 'https://releases.eu.cryptlex.com';

export const STRIPE_API_KEY_PROD = 'pk_live_ucK2xacdfNiyX4Q1JMGLXdwh';
export const STRIPE_API_KEY_DEV = 'pk_test_duxJ6mzAYCdO2VtmaUE2ZmWv';

export const GOOGLE_CLIENT_ID =
  '564989091024-3i5einfqvu6nkribl2nocv7nkjjas8ls.apps.googleusercontent.com';
export const RECAPTCHA_SITE_KEY = '6Lfo4bEaAAAAABWxs-Z3fAdXQ8SJmN-FztzDXjQy';

export const POSTHOG_API_KEY =
  'phc_Ib72c5ovN7A1eqOUEQUiaPRBd5KGLXiDQLBYNisEd75';
export const POSTHOG_API_HOST = 'https://insights.cryptlex.com';

export const PADDLE_VENDOR_ID_PROD = 151192;
export const PADDLE_VENDOR_ID_DEV = 7084;

export const PADDLE_TOKEN_PROD = 'live_67b9793bdee2e0a211877a6a3f5';
export const PADDLE_TOKEN_DEV = 'test_e685fa7e89302128d22b993a292';

export const CRYPTLEX_PORTAL_URL = 'https://app.cryptlex.com';

export const AdminPortalHostingDomain = 'hosting.app.cryptlex.com';
export const CustomerPortalHostingDomain = 'hosting.customer.cryptlex.com';
export const EuCustomerPortalHostingDomain = 'hosting.customer.eu.cryptlex.com';

export const ResellerPortalHostingDomain = 'hosting.reseller.cryptlex.com';
export const EuResellerPortalHostingDomain = 'hosting.reseller.eu.cryptlex.com';

export const DevResellerPortalHostingDomain =
  'hosting.reseller.dev.cryptlex.com';
export const DevCustomerPortalHostingDomain =
  'hosting.customer.dev.cryptlex.com';
export const DevAdminPortalHostingDomain = 'hosting.app.dev.cryptlex.com';
export const allowedTagsOnLicense = 15;
export const allowedTagsOnUsers = 5;
export const ignoredAccounts: { [key: string]: string } = {
  'd3055767-b6b8-4e3d-b641-eeddd7c230ae': '2025-02-23',
  '1dbaef15-6ae2-4689-ab01-7c63e8ac6803': '2025-04-10',
  '7c533d35-2e86-48e7-9843-33ba2909ced6': '2025-05-04',
  '7edc3eda-2704-43cf-a69e-f9a39bc7508b': '2025-05-09',
  '24f2c8b3-5b9f-4bca-80bc-f9fa0d46942d': '2025-05-17',
  '6466657d-fae3-450b-963c-dc9a1a1ded4e': '2025-05-17',
  '90ceba34-ab44-4a63-b742-2de8ad366a8f': '2025-08-03',
  'e331cd3f-006c-4ac5-8d02-5d0efaea109e': '2025-07-01',
  '8f51cba3-e7cc-49fe-87cd-4845b3f470b2': '2025-01-31',
  '8d73666c-07c4-4ea8-918e-46841252b0ab': '2025-05-10',
  '4dbaef41-6b23-4e36-845c-a109558505a3': '2025-04-29',
  'b0f64837-92ce-4178-bf51-031984965997': '2025-04-14',
  'a395666f-5bd6-4104-aca1-e3a71537010e': '2025-07-05',
};
