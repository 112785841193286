import { formatNumber } from '@angular/common';
import { dateDifference, formatSeconds } from './time';
import { getObjectProperty } from './objects';
import { CtxIcons } from './icons';
import { UNLIMITED_TEXT } from './unlimited-value';
/**
 *
 * @param license
 * @returns string of formatted validity for License
 */
export function formatLicenseValidity(license: any) {
  const _validity = getObjectProperty(license, 'validity');
  /** If 0, license is perpetual */
  if (_validity === 0) {
    return 'Lifetime';
  } else {
    /** Else return  */
    return `${formatSeconds(_validity)}`;
  }
}

/**
 *
 * @param license
 * @returns string of formatted expires in for License
 */
export function formatLicenseExpiresIn(license: any) {
  const _validity = getObjectProperty(license, 'validity');
  /** If 0, license is perpetual */
  if (typeof _validity === 'number' && _validity === 0) {
    return 'Never';
  } else if ('expiresAt' in license && license.expiresAt) {
    /** If expiry date is set, calculate time to expiration */
    const _expiryDate = new Date(license.expiresAt);
    const _differenceInSeconds = Math.ceil(dateDifference(_expiryDate));
    /** If license if expired */
    if (_differenceInSeconds < 0) {
      return 'Expired';
    }
    return `${
      _differenceInSeconds < 3600
        ? '0s'
        : formatSeconds(_differenceInSeconds, {
            hours: true,
            minutes: false,
            seconds: false,
          })
    }`;
  } else {
    /**Delayed license */
    return ``;
  }
}

/**
 * License types node-locked, hosted-floating, and  on-premise-floating are denoted by icons in the dashboard.
 *
 * @param type License type
 * @returns String identifier for the corresponding Material Icon
 */
export function getIconForLicenseType(type: string): CtxIcons {
  switch (type) {
    case 'node-locked':
      return 'NODE_LOCKED';
    case 'hosted-floating':
      return 'HOSTED_FLOATING';
    case 'on-premise-floating':
      return 'ON_PREMISE_FLOATING';
    default:
      /** hlm icon component does not accept undefined */
      return 'NODE_LOCKED';
  }
}

/**
 * Sort metadata as per key value if incase it's sorted by id
 * @param metadata metadata array
 * @returns sort metadata array by key value
 */
export function sortByKey(metadata: any[]) {
  return metadata.sort((a: any, b: any) => {
    const keyA = a.key;
    const keyB = b.key;

    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0;
  });
}
/**
 * Returns inifinity symbol if allowedActivations === 0 or -1.
 */
export function formatAllowedActivations(allowedActivations: number) {
  if (allowedActivations === 0 || allowedActivations === -1) {
    return UNLIMITED_TEXT;
  } else {
    return formatNumber(allowedActivations, 'en-US');
  }
}
/**
 * Returns unlimited text if allowedDeativations === -1.
 */
export function formatAllowedDeactivations(allowedDeactivations: number) {
  if (allowedDeactivations === -1) {
    return UNLIMITED_TEXT;
  } else {
    return formatNumber(allowedDeactivations, 'en-US');
  }
}

/**
 *
 * @param expiryDate
 * @returns the status of the expiry date
 */
export function getExpiryStatus(expiryDate: string) {
  const today = new Date().toISOString();
  if (expiryDate < today) {
    return 'Expired';
  } else {
    return 'Active';
  }
}

/**
 * Formats license activations as Allowed
 */
export function getLicenseActivations(license: any): string {
  return `${formatAllowedActivations(license.allowedActivations)}`;
}

/**
 * Format license deactivation as Total/Allowed
 * @param license
 */
export function getLicenseDeactivations(license: any): string {
  // TODO refactor when -1 validation support is added in the backend for allowedDeactivations for hosted floating
  if (license.type === 'hosted-floating') {
    return `${license.totalDeactivations} / ${UNLIMITED_TEXT}`;
  }
  if ('allowedDeactivations' in license && 'totalDeactivations' in license) {
    return `${license.totalDeactivations} / ${formatAllowedDeactivations(
      license.allowedDeactivations
    )}`;
  } else {
    return `${formatAllowedDeactivations(license.allowedDeactivations)}`;
  }
}

/**
 * Format multiple license parameters (expired, suspended, revoked) into a single status
 */
export function getLicenseStatus(license: any): string {
  const licenseExpired =
    license.expiresAt && new Date(license.expiresAt) < new Date();
  // Status Column
  switch (true) {
    case license.revoked && license.suspended && licenseExpired:
      return 'Revoked, Suspended, Expired';
    case license.revoked && license.suspended:
      return 'Revoked, Suspended';
    case license.revoked && licenseExpired:
      return 'Revoked, Expired';
    case license.suspended && licenseExpired:
      return 'Suspended, Expired';
    case license.suspended:
      return 'Suspended';
    case license.revoked:
      return 'Revoked';
    case licenseExpired:
      return 'Expired';
    default:
      return 'Active';
  }
}
