import { ComponentType } from '@angular/cdk/portal';
import { ThemeColors } from './theme.types';
import { CtxIcons } from '../lib/icons';
type _baseCustomToolbarAction = {
  label: string;
  color: ThemeColors;
  actionIcon: CtxIcons;
  disabled?: () => boolean;
};

export type CustomToolbarAction =
  | (_baseCustomToolbarAction & {
      isBulkOperation: true;
      callback: (selections: any[]) => Promise<void>;
    })
  | (_baseCustomToolbarAction & {
      isBulkOperation: false;
      callback: () => Promise<void>;
    });

export type TableMenuAction = {
  /** When the label depends on the `row` variable in the matCellDef, the labelCallback property takes preference over label. */
  labelCallback?: (resource: any) => string;
  /** Fallback label when labelCallback is not defined. */
  label: string;
  /** Callback function for the action to be performed */
  callback: (resource: any) => Promise<void>;
  /** MatIcon identifier for optional icon. */
  icon: CtxIcons | ((resource: any) => CtxIcons);
  /** Callback function to check availability of action*/
  disabled?: (resource: any) => boolean;
};

export type TableActions = {
  /** True if reading this resource is allowed. */
  read: boolean;

  /** True if creation of resource from dashboard is allowed. */
  create: boolean;

  /** True if the resource can be updated */
  update: boolean;

  /** True if the resource can be deleted */
  delete: boolean;

  /** True if the resource can be exported */
  export: boolean;

  /** True if the resource can be linked to tags. */
  tag: boolean;

  /** True if searching is supported. Search is defined by the `query` QueryParameter in the Web API */
  search: boolean;

  /** True if selection using checkboxes is allowed */
  selection: boolean;

  /** True if the resource has support for segments */
  segments: boolean;

  /** Actions on the resource that should be shown in the actions menu on the table and resourcePage  */
  menuActions?: TableMenuAction[];

  customToolbarActions?: CustomToolbarAction[];
};

export const TABLE_COLUMN_DISPLAY_TYPES = [
  /** Link is the first column after checkbox(if present). The link goes to an resource page with an advanced view */
  'link',
  /** A single tag */
  'tag',
  /** Array of tags */
  'tags',
  /** Operating System */
  'operatingSystem',
  /** Date-String */
  'date',
  /** Truncate after a certain width */
  'truncateText',
  /** Release Files */
  'files',
  /** Metadata */
  'metadata',
  /** Meter Attributes */
  'meterAttributes',
  /** Audit Log Changes */
  'auditLogChanges',
  /** Feature Flags */
  'featureFlags',
  /** Webhook Event Data */
  'webhookEventData',
  /** Automated Email Event Data */
  'automatedEmailEventData',
  /** Text / Default */
  'text',
  /** Uppercase boolean for enum values like Fingerprint Matching Strategy */
  'flag',
  /** Value in seconds */
  'seconds',
  /** Number */
  'number',
  /** File size */
  'fileSize',
  /** Used ONLY when link is not present. This is the first column after checkboxes if link is not available */
  'name',
  /** License validity */
  'licenseValidity',
  /** license expires in  */
  'licenseExpiresIn',
  /** License activations */
  'licenseActivations',
  /** License deactivations */
  'licenseDeactivations',
  /** License status */
  'licenseStatus',
  /** Allowed events */
  'allowedEvents',
  /** Product ID is replaced with name */
  'product',
  /** Product Version ID is replaced with name */
  'product-version',
  /** Maintenance Policy ID is replaced with name */
  'maintenance-policy',
  /** Webhook ID is replaced with name */
  'webhook',
  /** Automated Email ID is replaced with name */
  'automatedEmail',
  /** Notes */
  'notes',
  /** Notes that need md rendering */
  'md-notes',
  /**Display allowed permissions */
  'allowedPermission',
  /** Display the expiry status */
  'status',
  /** Incase column contain array */
  'arrayData',
  /** Display the metadata for activation */
  'activationMetadata',
  /** Maintenance Policy Validity */
  'maintenanceValidity',
  /** Display for properties for which unlimited is for -1 like resseller.allowedUsers, resseller.allowOrg */
  'unlimitedCheck',
  /** Displays archive icon is archived */
  'release',
] as const;
export type TableColumnDisplayType =
  (typeof TABLE_COLUMN_DISPLAY_TYPES)[number];

export type TableColumn = {
  /**
   * Defines the dot notation property to access from response object.
   */
  property: string;
  /**
   * Helps table decide formatting for the column.
   */
  displayType: TableColumnDisplayType;
  /**
   * Component for when property is view in modal
   */
  sortProperty?: string;
  modalComponent?: ComponentType<any>;
};

export type TableSortQueryParam = {
  sort: string;
};

export type TableSearchQueryParam = {
  /**
   * Property name to be replaced with 'search'
   */
  query: string;
};

export type TableFilterQueryParam = {
  [key: string]: (string | number | boolean)[];
};

/**
 * Set of properties essential to make a fully-working table
 */
export type TableProperties = {
  /** Object to make sort query to the Web API  */
  sort: TableSortQueryParam;

  /** Object to apply filter query to the Web API */
  filter: TableFilterQueryParam;

  /** Object to make search query to the Web API */
  search: TableSearchQueryParam;

  /** The current page index in table pagination */
  pageNumber: number;

  /** Table page size */
  pageSize: 10 | 20 | 30 | 50 | 100 | number;

  /** Total number of rows (in database) for the resource */
  rowCount: number;
};

/** Sorting ascending suffix operator */
export const TABLE_SORT_ASC_OPERATOR = '+';
/** Sorting descending suffix operator */
export const TABLE_SORT_DESC_OPERATOR = '-';

/**
 * Array of ObjectTable row display types
 */
export const OBJECT_TABLE_ROW_TYPES = [
  'valueOnly',
  'text',
  'date',
  'code',
  'seconds',
  'number',
  'flag',
  'licenseValidity',
  'product',
  'product-version',
  'country',
  'maintenance-policy',
  'tags',
] as const;
/**
 * Type for array of ObjectTable row displayTypes
 */
export type ObjectTableRowType = (typeof OBJECT_TABLE_ROW_TYPES)[number];
/**
 * ObjectTable row
 */
export type ObjectTableRow = {
  heading?: string;
  property: string;
  displayType: ObjectTableRowType;
  hide?: boolean;
};
